@mixin font-face($font-name, $font-family, $font-path, $font-weight: 'normal', $font-style: 'normal') {
  @font-face {
    font-family: $font-family;
    src: url('#{$font-path}/#{$font-name}.eot');
    src: url('#{$font-path}/#{$font-name}.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/#{$font-name}.ttf') format('truetype'),
    url('#{$font-path}/#{$font-name}.svg') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@mixin size($w, $h) {
  width: $w;
  height: $h;
}

@mixin size($s) {
  width: $s;
  height: $s;
}

@mixin self-sizing() {
  @for $i from 1 through 12 {
    $width: ($container-width + $padding-1 * 2) / 12 * $i;
    &.size-#{$i} {
      width: $width;
    }
  }
  @media (max-width: $lg) {
    @for $i from 1 through 12 {
      $width: ($container-width + $padding-1 * 2) / 12 * $i;
      &.size-lg-#{$i} {
        width: $width;
      }
    }
  }
  @media (max-width: $md) {
    @for $i from 1 through 12 {
      $width: ($container-width + $padding-1 * 2) / 12 * $i;
      &.size-md-#{$i} {
        width: $width;
      }
    }
  }
  @media (max-width: $sm) {
    @for $i from 1 through 12 {
      $width: ($container-width + $padding-1 * 2) / 12 * $i;
      &.size-sm-#{$i} {
        width: $width;
      }
    }
  }
  @media (max-width: $xs) {
    @for $i from 1 through 12 {
      $width: ($container-width + $padding-1 * 2) / 12 * $i;
      &.size-xs-#{$i} {
        width: $width;
      }
    }
  }
}

@mixin column-sizing($gap) {
  @for $i from 1 through 12 {
    $width: 100% / 12 * $i;
    $n: ((12/$i - 1)*$gap)/(12/$i);
    $width: calc(#{$width} - #{$n});
    &.order-#{$i} {
      order: $i;
    }
    &.size-#{$i} {
      flex-basis: $width;
      max-width: $width;
    }
  }
  @media (max-width: $lg) {
    @for $i from 1 through 12 {
      $width: 100% / 12 * $i;
      $n: ((12/$i - 1)*$gap)/(12/$i);
      $width: calc(#{$width} - #{$n});
      &.order-lg-#{$i} {
        order: $i;
      }
      &.size-lg-#{$i} {
        flex-basis: $width;
        max-width: $width;
      }
    }
  }
  @media (max-width: $md) {
    @for $i from 1 through 12 {
      $width: 100% / 12 * $i;
      $n: ((12/$i - 1)*$gap)/(12/$i);
      $width: calc(#{$width} - #{$n});
      &.order-md-#{$i} {
        order: $i;
      }
      &.size-md-#{$i} {
        flex-basis: $width;
        max-width: $width;
      }
    }
  }
  @media (max-width: $sm) {
    @for $i from 1 through 12 {
      $width: 100% / 12 * $i;
      $n: ((12/$i - 1)*$gap)/(12/$i);
      $width: calc(#{$width} - #{$n});
      &.order-sm-#{$i} {
        order: $i;
      }
      &.size-sm-#{$i} {
        flex-basis: $width;
        max-width: $width;
      }
    }
  }
  @media (max-width: $xs) {
    @for $i from 1 through 12 {
      $width: 100% / 12 * $i;
      $n: ((12/$i - 1)*$gap)/(12/$i);
      $width: calc(#{$width} - #{$n});
      &.order-xs-#{$i} {
        order: $i;
      }
      &.size-xs-#{$i} {
        flex-basis: $width;
        max-width: $width;
      }
    }
  }
}