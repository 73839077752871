.transaction-record {
  &.mobile {
    padding: 12px 10px;
    .status-block {
      position: relative;
      width: 34px;
      height: 34px;
      .action-icon {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        color: #fff;
        width: 34px;
        height: 34px;
        border-radius: 1000px;
        overflow: hidden;
        font-size: 12px;
        &.deposit {
          background: linear-gradient(45.79deg, #07A624 0%, #48DA89 94.75%, #40C47A 94.75%);
        }
        &.withdrawal {
          background: linear-gradient(41.83deg, #E54C28 2.18%, #E5A266 97.5%);
        }
      }

      .status-icon {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -1px;
        bottom: -1px;
        width: 12px;
        height: 12px;
        border-radius: 1000px;
        color: #fff;
        font-size: 6px;
        box-shadow: 0 0 0 1px #fff;
        z-index: 1;
        &.error {
          background: $color-2;
        }
        &.success {
          background: $color-4;
        }
        &.process {
          background: $color-5;
        }
        &.cancel {
          background: $color-6;
        }
      }
    }
    .item-id {
      display: block;
      width: 100%;
      line-height: 100%;
      font-size: 14px;
      font-weight: 400;
      & + * {
        margin-top: 8px;
      }
    }
    .date-time {
      display: block;
      width: 100%;
      color: $color-9;
      font-size: 12px;
      line-height: 100%;
    }
    .amount {
      text-align: right;
      font-weight: 600;
      font-size: 14px;
      &.deposit {
        color: $color-4;
      }
      &.error {
        color: $color-2;
      }
    }
    .destination-payment {
      text-align: right;
      color: $color-9;
      font-size: 12px;
    }
  }
}