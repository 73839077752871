.sites-page {
  .site-record {
    &.mobile {
      padding: 12px 10px;
      display: block;
      .image-block {
        position: relative;
        width: 34px;
        height: 34px;
        .text-icon {
          display: flex;
          flex-shrink: 0;
          text-transform: capitalize;
          align-items: center;
          justify-content: center;
          color: #fff;
          width: 34px;
          height: 34px;
          border-radius: 1000px;
          overflow: hidden;
          font-size: 14px;
          font-weight: 600;
          background: linear-gradient(45deg, #2724CE 0%, #049DF3 100%);
        }
        .status-icon {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -1px;
          bottom: -1px;
          width: 12px;
          height: 12px;
          border-radius: 1000px;
          color: #fff;
          font-size: 6px;
          box-shadow: 0 0 0 1px #fff;
          z-index: 1;
          &.error {
            background: $color-2;
          }
          &.success {
            background: $color-4;
          }
          &.process {
          background: $color-5;
        }
        &.cancel {
            background: $color-6;
          }
        }
      }
      .item-name {
        font-size: 12px;
        font-weight: 500;
        color: $color;
        span {
          &:first-letter {
            text-transform: capitalize;
          }
        }
      }
      .item-url {
        color: $color-9;
        font-size: 10px;
        font-weight: 400;
        margin-top: 4px;
      }
      .item-balance {
        color: $color-3;
        font-size: 12px;
        font-weight: 600;
      }
      .item-activity {
        color: $color-9;
        font-size: 10px;
        font-weight: 500;
        width: auto;
        margin-top: 4px;
      }
    }
  }
}

.site-detail-page {
  .site-detail-card {
    &.mobile {
      .site-info {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        .info {
          margin: 0;
        }
      }
      .top-part {
        &>.columns-row {
          gap: 5px;
        }
      }
      .site-edit {
        width: 27px;
        height: 27px;
      }
    }
    .payment-accounts-tab {
      &.mobile {
        .account-items {
          &>.item {
            opacity: 1;
            border: 1px solid #D8D6DE;
            cursor: auto;
            &>.toggle-pane {
              cursor: pointer;
            }
            &:hover {
              &:not(.active) {
                opacity: 1;
              }
            }
          }
          .tabs {
            &>.tab-panel {
              gap: 10px;
              &>.item {
                flex-grow: 1;
                padding: 0 0 10px 0;
                &.active {
                  color: $color;
                  &:before {
                    background: #28C76F;
                  }
                  &.withdrawal {
                    &:before {
                      background: #FF9F43;
                    }
                  }
                }
              }
            }
          }
        }
        .method-item {
          .method-source {
            font-size: 14px;
          }
          .method-commission {
            font-size: 12px;
            font-weight: 400;
            color: $color;
            text-align: right;
            .commission-value, .commission-text {
              display: block;
            }
            .commission-text {
              font-weight: 600;
              text-align: left;
              & + * {
                margin-top: 5px;
              }
            }
          }
        }
        .site-balance-buttons {
          [class*="bt"]{
            &.site-balance-button {
              width: 140px;
            }
          }
        }
      }
    }
    .API-tab {
      &.mobile {
        .bt-icon {
          color: $color-12;
        }
        .text-value {
          font-weight: 500;
          font-size: 15px;
          margin-top: 10px;
        }
        .ip-list-status {
          font-weight: 500;
          font-size: 15px;
          margin-top: 10px;
          & + * {
            margin-top: 10px;
          }
        }
        .ip-list {
          & > .item {
            gap: 5px;
          }
        }
      }
    }
    .urls-tab {
      &.mobile {
        .urls-block {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: $sm - 1) {
  .modal-dialog {
    &.ip-whitelist-api {
      .status-image {
        font-size: 100px;
      }
      .list-items {
        gap: 28px;
        .list-item {
          &>.columns-row {
            row-gap: 7px;
          }
        }
      }
    }
  }
}
@media (max-width: $xs) {
  .site-detail-page {
    .site-detail-card {
      &.mobile {
        .top-part {
          .buttons-holder {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(180px, auto));
          }
        }
      }
    }
  }
  .modal-dialog {
    &.exchange-balance {
      .current-rate {
        font-size: 12px;
      }
      .timer {
        font-size: 12px;
        width: 85px;
      }
      .status-holder {
        &.final {
          .icon-status {
            .icon-success {
              font-size: 60px;
            }
          }
          .current-rate {
            font-size: 14px;
          }
          .exchange-result {
            flex-direction: column;
            align-items: stretch;
            width: 280px;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
            .icon-separate {
              justify-content: center;
              .font-icons {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }
}