.text-block {
  .text-color-alert {
    color: $color-5;
  }
  .color-primary {
    color: $color-3;
  }
  .color-inactive {
    color: $color-6;
  }
}