@import "style-adaptive.scss";

.transactions-holder {
  margin-top: 15px;
}

.transaction-record {
  color: #5E5873;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  margin-bottom: 1px;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.06));
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 10px rgba(130, 134, 139, 0.65);
  }
  & + .transaction-record {
    margin-top: 14px;
  }
  .left-part {
    padding: 20px 8px;
    width: 88%;
    .col-id {
      flex-basis: 16.66%;
      max-width: 16.66%;
    }
    .col-type {
      flex-basis: 16.66%;
      max-width: 16.66%;
      .sub-caption {
        color: #6E6B7B;
      }
    }
    .col-date {
      flex-basis: 11.66%;
      max-width:  11.66%;
      .sub-caption {
        color: #5E5873;
      }
    }
    .col-site {
      flex-basis: 22.66%;
      max-width: 22.66%;
      .sub-caption {
        color: #9A9A9A;
      }
    }
    .col-contractor {
      flex-basis: 18.66%;
      max-width: 18.66%;
    }
    .col-status {
      flex-basis: 13.66%;
      max-width: 13.66%;
    }
  }
  .right-part {
    padding: 18px;
    text-align: right;
    justify-content: flex-end;
    color: #fff;
    width: 12%;
    &.deposit {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(45.79deg, #02B51E 0%, #48DA89 100%, #48DA68 100%);
    }
    &.withdrawal {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(41.83deg, #FF502A 2.18%, #FFB976 100%);
    }
  }
  .card-columns {
    gap: 0;
    .card-column {
      padding: 0px 10px;
    }
  }
  .item-id {
    font-weight: 500;
    display: flex;
    gap: 5px;
  }
  .date {
    color: #5E5873;
    font-weight: 500;
  }
  .time {
    font-size: 12px;
  }
  .site-name {
    color: $color-3;
  }
  .site-id {

  }
  .amount {
    font-weight: 700;
  }
  .columns {
    display: flex;
    gap: 8px;
    flex-shrink: 0;
    &>.column {
      &.direction-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &.align-center {
        display: flex;
        align-items: center;
      }
    }
    .heading-caption {
      text-transform: capitalize;
    }
    .sub-caption {
      font-size: 12px;
    }
  }
}

.transaction-view {
  display: flex;
  flex-direction: row;
  cursor: unset;
  color: #5E5873;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  margin-bottom: 50px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  overflow: hidden;
  .left-part {
    width: auto;
    flex-basis: 0;
    flex-grow: 1;
    padding: 15px 18px;
    &>.columns-grid {
      grid-template-columns: 1fr auto;
      gap: 0 $padding-1;
      height: 100%;
    }
  }
  .amount-block {
    width: 285px;
    padding: 15px 18px;
    text-align: left;
    display: flex;
    flex-direction: column;
    color: #fff;
    &.deposit {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(45.79deg, #02B51E 0%, #48DA89 100%, #48DA68 100%);
    }
    &.withdrawal {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(41.83deg, #FF502A 2.18%, #FFB976 100%);
    }
    .heading-caption {
      color: inherit;
    }
    .action-method {
      & + * {
        margin-top: 14px;
      }
    }
    .top-part {
      flex-grow: 1;
    }
    .bottom-part {

    }
    .amount {
      font-weight: 700;
      font-size: 28px;
      text-align: left;
      & + * {
        margin-top: 14px;
      }
    }
    .progress {
      height: 6px;
      background-color: rgba(255, 255, 255, 0.53);
      &.overpaid {
        border: 1px solid #fff;
        background: #fff;
        .progress-bar {
          background-color: #EA5455;
        }
      }
      & + * {
        margin-top: 14px;
      }
      .progress-bar {
        background: #fff;
      }
    }
    .payed {
      font-size: 14px;
      font-weight: 700;
      & + * {
        margin-top: 4px;
      }
    }
    .commission {
      font-size: 12px;
      font-weight: 700;
    }
    .receipts {
      margin-top: 25px;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .heading-caption {
        & + * {
          margin-top: 5px;
        }
      }
      .text-value {
        align-items: center;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .downloads {
      margin-top: 25px;
      .item {
        & + * {
          margin-top: 14px;
        }
      }
      .number {
        font-weight: 600;
      }
      .text {
        width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
        font-weight: 700;
        font-size: 12px;
        color: #fff;
      }
    }
  }
  .payment-number {
    font-size: 14px;
    display: flex;
    align-items: baseline;
    gap: 14px;
    margin-bottom: 14px;
    justify-content: flex-end;
    .caption {
      font-size: 16px;
    }
    .value {
      font-weight: 500;
      font-size: 14px;
    }
  }
  .date {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 14px;
    margin: 0;
    text-align: left;
    .caption {

    }
    .value {
      font-size: 12px;
      font-weight: 600;
    }
  }
  .site-block {
    margin: 50px 0;
    .site-name {
      color: $color-3;
      font-size: 28px;
      font-weight: 700;
    }
  }
  .site-info {
    margin-top: 50px;
    .caption {
      font-size: 16px;
      margin-bottom: 14px;
    }
    .value {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .notifications {
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: column;
    .items {
      display: flex;
      gap: 18px;
      flex-wrap: wrap;
    }
    .item {
      border: 1px solid #EBE9F1;
      border-radius: 7px;
      padding: 9px 11px;
      max-width: 252px;
      width: 100%;
      display: grid;
      gap: 14px;
      &:hover {
        border: 1px solid #82868B;
        cursor: pointer;
      }
      .status {
        border-radius: 1000px;
        width: 8px;
        height: 8px;
        &.success {
          background: linear-gradient(45.79deg, #28C76F 0%, #48DA89 94.75%, #48DA89 94.75%);
        }
        & + * {
          margin-left: 7px;
        }
      }
      .heading-caption {
        font-weight: 600;
      }
      .date {
        justify-content: space-between;
        font-size: 12px;
      }
    }
  }
  .comment {
    grid-column: 1 / span 2;
    & + * {
      margin-top: 28px;
    }
    .text {
      font-size: 18px;
      font-weight: 500;
    }
  }
  &.mobile {
    flex-direction: column;
    .left-part {
      padding: 15px 11px;
    }
    .transaction-id-status {
      & + * {
        margin-top: 20px;
      }
      .columns {
        display: flex;
        gap: 8px;
        align-items: center;
        &>.column {
          &.align-center {
            display: flex;
            align-items: center;
          }
        }
        .heading-caption {
          text-transform: capitalize;
        }
        .sub-caption {
          font-size: 12px;
        }
      }
    }
    .transaction-status {
      display: flex;
    }
    .transaction-status-message {
      margin-top: 7px;
      & + * {
        margin-top: 14px;
      }
    }
    .payment-number {
      margin-bottom: 7px;
      justify-content: space-between;
      .caption {
        font-size: 12px;
      }
      .value {
        font-weight: 500;
      }
    }

    .date {
      & + .date {
        margin-top: 2px;
      }
      justify-content: space-between;
      .caption {
        font-size: 12px;
      }
      .value {
        font-weight: 500;
      }
    }
    .site-block {
      margin: 25px 0;
      .site-name {
        font-size: 24px;
      }
    }
    .transaction-info {
      .caption {
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 5px;
      }
      .value {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .amount-block {
      width: 100%;
      margin-top: 14px;
      padding: 15px 11px;
      .amount {
        font-size: 21px;
      }
      .receipts {

      }
      .downloads {
        .items {
          padding-top: 0.8em;
        }
        .item + * {
          margin-top: 10px;
        }
      }
      .collapse-toggle {
        cursor: pointer;
        color: #fff;
        .img-holder {
          filter: brightness(1000%);
          transition: 0.3s ease-out all;
        }
        &.active {
          .img-holder {
            transform: rotate(180deg);
          }
        }
      }
    }
    .notifications {
      display: block;
      margin-top: 25px;
      .collapse-toggle {
        cursor: pointer;
        .img-holder {
          height: 14px;
          transition: 0.3s ease-out all;
        }
        &.active {
          .img-holder {
            transform: rotate(180deg);
          }
        }
      }
      .items {
        padding-top: 10px;
      }
    }
    .comment {
      display: block;
      margin-top: 25px;
      .collapse-toggle {
        cursor: pointer;
        .img-holder {
          height: 14px;
          transition: 0.3s ease-out all;
        }
        &.active {
          .img-holder {
            transform: rotate(180deg);
          }
        }
      }
      .text {
        font-size: 14px;
        padding-top: 10px;
      }
    }
  }
}

.transactions-header {
  &.mobile {
    .buttons-section {
      row-gap: 30px;
    }
  }
}