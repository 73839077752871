@import "../fonts/font-icons/style.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap");
/*
.font-face(
  @font-name: 'Tahoma',
  @font-family: 'Tahoma',
  @font-path: '/catalog/view/theme/default/assets/fonts/tahoma',
  @font-weight: 400,
  @font-style: 'normal'
);

.font-face(
  @font-name: 'Tahoma-Bold',
  @font-family: 'Tahoma',
  @font-path: '/catalog/view/theme/default/assets/fonts/tahoma',
  @font-weight: 600,
  @font-style: 'normal'
);
 */