//--URLs--
$image-url: "";
$icon-url: "";
$shape-url: "../images";

//-- colors --
$color: #5E5873;
$color-0: #403434;
$color-1: #533316;
$color-2: #EA5455;
$color-3: #7367F0;
$color-4: #28C76F;
$color-5: #FF9F43;
$color-6: #82868B;
$color-8: #0D6EFD;
$color-9: #6E6B7B;
$color-10: #6610F2;
$color-11: #D63384;
$color-12: #B9B9C3;


//-- gradients --
$gradient-1: linear-gradient(45.79deg, #07A624 0%, #48DA89 94.75%, #40C47A 94.75%);
















//--margins
$margin-1: 12px;
$padding-1: 15px;

// -- container-width --
$container-width: 1270px;

// -- responsive --
$lg: 1200px;
$md: 992px;
$sm: 768px;
$xs: 576px;

//-- font-icons
