.main-menu {
  .navbar-header {
    .navbar-brand {
      .brand-text {
        padding-left: 0;
      }
    }
  }
}

.auth-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  .app-logo {
    font-size: 24px;
    font-weight: 700;
    color: $color-3;
    text-align: center;
    & + * {
      margin-top: 15px;
    }
  }
  .heading-block {
    margin-bottom: 25px;
  }
  .heading-caption {
    &.s-4 {
      & + .sub-caption {
        margin-top: 8px;
        color: $color-9;
      }
    }
  }
  .form-block-wrapper {
    padding: 70px;
    background: url("#{$shape-url}/shapes/shape-rectangles-1.svg") no-repeat left top, url("#{$shape-url}/shapes/shape-rectangles-2.svg") no-repeat right bottom;
  }
  .form-block {
    width: 400px;
    max-width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 30px 32px;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;
  }
  .auth-form {
    & + * {
      margin-top: 20px;
    }
  }

  .buttons-holder {
    & + .text {
      margin-top: 20px;
    }
  }

  .text {
    & + * {
      margin-top: 7px;
    }
  }
  .form-check {
    margin: 5px 0;
  }
}
