.sites-page {
  .cards-list {
    display: grid;
    gap: 15px;
  }
  .site-record {
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.06));
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    margin: 0;
    padding: 0;
    box-shadow: none;
    display: grid;
    grid-template-columns: auto 150px;
    &:hover {
      box-shadow: none;
    }
    .left-part {
      padding: 0 18px;
      display: grid;
      gap: 15px;
      grid-template-columns: 150px 1fr 2fr 1fr 110px;
      min-height: 50px;
      align-items: center;
    }
    .right-part {
      padding: 0 18px;
      display: flex;
      gap: 7px;
      text-align: right;
      justify-content: flex-end;
      align-items: center;
      color: #fff;
      background: linear-gradient(45deg, #7367F0 0%, #9E95F5 100%);
      .item-balance {
        font-weight: 700;
      }
    }
    .col-id {

    }
    .col-date {
      text-align: center;
    }
    .col-status {
      text-align: center;
    }
    .col-request {
      text-align: center;
    }
    .col-response {
      text-align: center;
    }
    .item-id {
      display: flex;
      gap: 5px;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        width: 0;
      }
    }
    .item-activity {
      display: flex;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        width: 0;
      }
    }
    .item-name {
      font-weight: 700;
      display: flex;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        width: 0;
      }
    }
    .item-url {
      font-weight: 700;
      display: flex;
      color: $color-3;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        width: 0;
      }
    }
  }
}

.site-detail-page {
  .site-detail-card {
    box-shadow: 0px 4px 14px rgba(75, 75, 75, 0.38);
    background: #fff;
    border-radius: 10px;
    .payment-accounts-tab {
      .line-divider {
        margin: 0 20px;
      }
      .left-part {
        width: 235px;
        .fallback-spinner {
          height: auto;
        }
      }
      .right-part {
        .heading-caption {
          &.s-4 {
            margin-bottom: 20px;
          }
        }
      }
      .site-balance-buttons {
        & + * {
          margin-top: 32px;
        }
        [class*=bt].site-balance-button {
          padding: 16px 12px;
          gap: 10px;
          flex-wrap: wrap;
          width: 175px;
          max-width: 100%;
          white-space: normal;
          justify-content: flex-start;
          text-align: left;
          font-size: 14px;
          .font-icons {
            font-size: 28px;
          }
        }
      }
      .account-items {
        &>.item {
          opacity: 0.35;
          cursor: pointer;
          border-radius: 6px;
          padding: 10px;
          border: 1px solid #82868B;
          box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
          transition: 0.2s ease-out all;
          &:hover {
            &:not(.active) {
              opacity: 0.7;
            }
          }
          &>.columns-row {
            gap: 10px;
          }
          & + * {
            margin-top: 14px;
          }
          &.active {
            opacity: 1;
          }
          .icon {
            width: 47px;
            height: 47px;
            display: flex;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .heading-caption {
            margin-bottom: 7px;
          }
          .sub-caption {
            font-size: 12px;
          }
          .toggle-collapse {
            display: flex;
            transition: 0.3s ease-out all;
            transform: rotate(180deg);
          }
          .collapsed-content {
            padding-top: 14px;
          }
          &.collapsed {
            .toggle-collapse {
              transform: rotate(0deg);
            }
            .collapsed-content {
              display: none;
            }
          }
        }
      }
    }
    .urls-tab {
      .urls-block {
        gap: 30px;
      }
      .url {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        //gap: 10px;
        span {
          width: 0;
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .invoices-tab {
      .items-list {
        gap: 30px;
      }
      .item {
        .url {

        }
        .text {
          margin-top: 10px;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
    .limits-tab {

    }
    .API-tab {
      .record-row {
        & + * {
          margin-top: 35px;
        }
      }
      .heading-block {
        font-size: 12px;
        .heading-caption {
          margin-bottom: 5px;
        }
      }
      .text-value {
        font-weight: 500;
        color: $color-3;
      }
      .ip-list-status  {
        color: $color-3;
        font-weight: 500;
        & + * {
          margin-top: 28px;
        }
      }
      .ip-list {
        .item {
          font-size: 12px;
          & + * {
            margin-top: 12px;
          }
          .ip {
            color: $color-3;
          }
          .description {
            color: $color-9;
          }
        }
      }
    }
    .top-part {
      border-radius: 10px 10px 0 0;
      padding: 15px 18px 10px 18px;
      display: grid;
      gap: 60px;
      .name {
        text-transform: capitalize;
      }
      .amount {
        font-size: 32px;
        font-weight: 700;
        line-height: 1;
        text-align: right;
      }
      .info {
        margin-top: 6px;
        display: flex;
        gap: 6px;
      }
      .limit {
        font-size: 16px;
        font-weight: 400;
      }
      .buttons-holder {
        gap: 7px;
        .site-edit {
          margin-left: 20px;
        }
        .whitelist-enabled {
          color: $color-4;
        }
        .whitelist-disabled {
          color: $color-2;

        }
      }
    }
    .bottom-part {
      padding: 10px 18px 15px 18px;
      .methods-captions {
        margin-bottom: 16px;
        font-size: 12px;
      }
      .input-methods, .withdrawal-methods {
        .icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          flex-shrink: 0;
          border-radius: 1000px;
          overflow: hidden;
          width: 20px;
          height: 20px;
          font-size: 7px;
          margin-right: 4px;
        }
      }
      .input-methods {
        .icon {
          background: linear-gradient(46deg, #07A624 0%, #48DA89 100%, #40C47A 100%);
        }
      }
      .withdrawal-methods {
        .icon {
          background: linear-gradient(42deg, #E54C28 0%, #E5A266 99.98%);
        }
      }
      .method-item {
        padding: 9px 0;
        border: 1px solid #EBE9F1;
        border-radius: 6px;

        &.disable {
          background: #F3F2F7;
          & > * {
            opacity: 0.5;
          }
        }
        & + * {
          margin-top: 10px;
        }
        &.mobile {
          padding: 9px 8px;
          .column {
            border: none;
          }
        }
        .no-gap {
          .column {
            border-right: 1px solid #EBE9F1;
            padding: 0 10px;
            &:last-child {
              border: none;
            }
          }
        }

        .method-source {
          font-size: 12px;
          font-weight: 600;
        }
        .method-limit {
          font-size: 12px;
        }
        .method-commission {
          font-size: 12px;
          font-weight: 600;
          color: #FF9F43;
          .ui.bulleted.list,
          ul.ui.list {
            margin-left: 10px;
            text-align: left;
            width: 100%;
            font-weight: 400;
          }
          .ui.bulleted.list,
          ul.ui.list {
            .item {
              & > div {
                &:not(.list) {
                  font-weight: 600;
                }
              }
            }
          }
          .ui.bulleted.list .list>.item,
          .ui.bulleted.list>.item,
          ul.ui.list li {
            position: relative
          }
          .ui.bulleted.list .list>.item:before,
          .ui.bulleted.list>.item:before,
          ul.ui.list li:before {
            color: inherit;
            content: "•";
            font-weight: 400;
            left: auto;
            margin-left: -10px;
            opacity: 1;
            pointer-events: none;
            position: absolute;
            top: auto;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            vertical-align: top
          }
          .ui.bulleted.list .list>a.item:before,
          .ui.bulleted.list>a.item:before,
          ul.ui.list li:before {
            color: rgba(0,0,0,.87)
          }
          .ui.bulleted.list .list,
          ul.ui.list ul {
            padding-left: 10px
          }
        }
      }
    }
  }
}

.modal-dialog {
  &.ip-whitelist-api {
    .status-image {
      display: flex;
      justify-content: center;
      font-size: 120px;
      .font-icons {
        font-size: inherit;
        margin: 0;
        &.success {
          color: $color-4;
        }
        &.alert {
          color: $color-5;
        }
      }
      & + * {
        margin-top: 25px;
      }
    }
    .ip-list-block {
      margin-top: 28px;
      border-top: 1px solid #EBE9F1;
      padding-top: 14px;
      padding-bottom: 14px;
      .line-divider {
        margin: 14px 0;
      }
      .current-ip {
        display: inline-flex;
        gap: 5px;
        align-items: baseline;
        cursor: pointer;
      }
      .bt-icon{
        &.button-delete-ip {
          display: flex;
          font-size: 21px;
          color: $color-6;
          width: auto;
          .font-icons {
            font-size: inherit;
          }
        }
      }
      .list-items {
        display: grid;
        gap: 14px;
        margin-top: 14px;
      }
    }
  }
  &.transfer-balance {
    .bt {
      margin-top: 20px;
    }
    .transaction-id {
      color: $color-3;
      font-size: 16px;
      text-align: center;
    }
    .transfer-amount {
      text-align: center;
      & + * {
        margin-top: 15px;
      }
    }
    .payment-system {
      margin-top: 14px;
      display: flex;
      gap: 8px;
      font-size: 16px;
      justify-content: center;
      & + * {
        margin-top: 10px;
      }
      .payment-icon {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
      }
    }
    .field {
      .react-select {
        .select__single-value {
          //display: none;
        }
        .select-label {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .site-balance {
            font-weight: 500;
            color: $color-3;
          }
        }
      }
    }
  }
  &.exchange-balance {
    .current-site {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      .site-icon {
        text-transform: capitalize;
        color: #fff;
        width: 24px;
        height: 24px;
        font-weight: 600;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1000px;
        background: linear-gradient(42deg, #E54C28 2.18%, #E5A266 97.5%);
      }
      .heading-caption {
        display: block;
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
    .modal-header {
      &.no-padding {
        padding: 0;
      }
    }

    .modal-title {
      .heading-caption + * {
        margin-top: 15px;
      }
    }
    .fields-block {
      & + .fields-block {
        margin-top: 30px;
      }
      & + * {
        margin-top: 14px;
      }
    }
    .payment-account-field {
      &.disabled {
        .select--is-disabled, .select__control {
          background: #fff;
          color: $color;
          -webkit-text-fill-color: $color;
        }
        .select__indicators {
          opacity: 0.3;
        }
        &:not(.error) {
          .select--is-disabled, .select__control {
            border-color: #EFEFEF;
          }
        }
      }
      .select__value-container {
        padding: 10px;
      }
      .select__menu {
        .select__menu-list {
          .select__option {
            &.select__option--is-selected {
              background: #f2f2f2;
            }
          }
        }
      }
    }
    .payment-system {
      display: flex;
      gap: 8px;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      color: $color;
      & + * {
        margin-top: 10px;
      }
      .payment-icon {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .account-balance {
        font-size: 15px;
        font-weight: 500;
        & + * {
          margin-top: 4px;
        }
      }
      .account-label {
        font-size: 12px;
        font-weight: 400;
        display: block;
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
    .timer {
      display: flex;
      align-items: center;
      gap: 6px;
      width: 90px;
      .img-holder {
        flex-shrink: 0;
      }
    }

    .status-holder {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-direction: column;
      min-height:200px;
      flex-grow: 1;
      padding-top: 12px;
      padding-bottom: 12px;
      .icon-status {
        display: flex;
        justify-content: center;
        .icon-success {
          color: $color-4;
          font-size: 84px;
        }
        & + * {
          margin-top: 20px;
        }
      }
      .heading-caption {
        &.no-margin + * {
          margin-top: 8px;
        }
      }
      .description {
        font-size: 14px;
        font-weight: 400;
        color: $color;
        text-align: center;
        .payment-amount {
          color: #000;
        }
      }
      &.final {
        .heading-caption {
          & + * {
            margin-top: 30px;
          }
        }
        .current-site {
          & + * {
            margin-top: 10px;
          }
        }
        .current-rate {
          text-align: center;
          font-size: 16px;
          & + * {
            margin-top: 40px;
          }
        }
        .exchange-result {
          gap: 25px;
          .payment-item {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: center;
            text-align: center;
            color: $color;
            padding: 20px 10px;
            width: 100%;
            border-radius: 6px;
            border: 1px solid #EBE9F1;
            .payment-icon {
              flex-shrink: 0;
              width: 40px;
              height: 40px;
              margin-left: auto;
              margin-right: auto;
              & + * {
                margin-top: 8px;
              }
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
            .account-label {
              font-size: 15px;
              font-weight: 500;
              text-align: center;
              &:first-letter {
                text-transform: uppercase;
              }
              & + * {
                margin-top: 12px;
              }
            }
            .amount {
              font-size: 18px;
              font-weight: 500;
              display: block;
              &:first-letter {
                text-transform: uppercase;
              }
            }
          }
        }
        .icon-separate {
          font-size: 20px;
        }
      }
    }

    .bt {
      margin-top: 20px;
    }
    .transfer-amount {
      text-align: center;
      & + * {
        margin-top: 15px;
      }
    }

    .field {
      .react-select {
        .select__single-value {
          //display: none;
        }
        .select-label {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}

@import "style-adaptive";