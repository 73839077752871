@import "style-adaptive.scss";

.site-balances-page {
  .cards-header {
    margin-bottom: 15px;
  }
  .cards-list {
    display: grid;
    gap: 15px;
  }
  .site-balances-record {
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.06));
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    margin: 0;
    padding: 0;
    box-shadow: none;
    display: grid;
    grid-template-columns: auto 245px;
    &:hover {
      box-shadow: none;
    }
    .left-part {
      padding: 10px 18px;
      display: grid;
      gap: 15px;
      grid-template-columns: 150px 90px auto 120px;
      min-height: 80px;
      align-items: center;
    }
    .right-part {
      padding: 10px 18px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      text-align: center;
      justify-content: center;
      align-items: center;
      color: #fff;
      background: linear-gradient(45deg, #7367F0 0%, #9E95F5 100%);
      &.deposit {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(45.79deg, #02B51E 0%, #48DA89 100%, #48DA68 100%);
      }
      &.withdrawal {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(41.83deg, #FF502A 2.18%, #FFB976 100%);
      }
    }
    .col-id {

    }
    .col-transaction-id {
      text-align: center;
    }
    .col-date {
      text-align: left;
    }
    .item-id {
      display: flex;
      gap: 5px;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        width: 0;
      }
    }
    .site-name {
      font-weight: 700;
      display: flex;
      color: $color-3;
      & + * {
        margin-top: 5px;
      }
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        width: 0;
      }
    }
    .date {
      font-weight: 500;
      & + * {
        margin-top: 5px;
      }
    }
    .time {
      font-size: 12px;
    }
    .transaction-id {
      font-weight: 700;
    }
    .amount {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }
    .amount-change {
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #fff;
      span {
        white-space: nowrap;
      }
      i {
        font-size: 10px;
      }
    }
  }
}

.site-balance-view-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  overflow: hidden;
  .top-part {
    padding: 18px;
    color: #fff;
    background: linear-gradient(45deg, #7367F0 0%, #9E95F5 100%);
    &.deposit {
      background: linear-gradient(45.79deg, #07A624 0%, #48DA89 100%, #40C47A 100%);
    }
    &.withdrawal {
      background: linear-gradient(41.83deg, #E54C28 2.18%, #E5A266 100%);
    }
    .heading-caption {
      & + .sub-caption {
        margin-top: 5px;
        color: #fff;
        * {
          color: inherit;
        }
      }
    }
    .col-date-time {
      flex-shrink: 0;
    }
    .date {
      text-align: right;
      font-weight: 700;
    }
    .time {
      text-align: right;
      font-size: 12px;
    }
  }
  .bottom-part {
    padding: 28px 18px;
    .deposit {
      color: #1ab041;
    }
    .withdrawal {
      color: #e57446;
    }
    .caption {
      font-size: 16px;
      margin-bottom: 10px;
      text-align: center;
    }
    .amount {
      font-size: 28px;
      font-weight: 500;
      text-align: center;
    }
    .first-column {
      border-right: 1px solid #EBE9F1;
    }
  }
  .col-status-text {
    margin-left: 15px;
  }
}