.modal-dialog {
  &.create-new-invoice {
    .modal-header {
      &.no-padding {
        padding: 0;
      }
    }
    .modal-title {
      .heading-caption {
        & + .sub-caption {
          margin-top: 7px;
        }
      }
    }
    .status-holder {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-direction: column;
      min-height:200px;
      flex-grow: 1;
      padding-top: 12px;
      padding-bottom: 12px;
      .icon-status {
        display: flex;
        justify-content: center;
        .icon-success {
          color: $color-4;
          font-size: 84px;
        }
        & + * {
          margin-top: 20px;
        }
      }

      &.final {
        .heading-caption {
          & + * {
            margin-top: 12px;
          }
        }
        .form-buttons {
          color: #fff;
          margin-top: 32px;
        }
      }
    }
  }
}

@media (max-width: $xs) {
  .modal-dialog {
    &.create-new-invoice {
      .status-holder {
        .icon-status {
          .icon-success {
            color: $color-4;
            font-size: 64px;
          }
        }
      }
    }
  }
}