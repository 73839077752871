@media (max-width: $lg) {

}

@media (max-width: $md) {
  .pagination {
    background: #F3F2F7;
    border-radius: 1000px;
    .page-item {
      &.disabled {
        .font-icons {
          opacity: 0.5;
        }
      }
      &.prev-item, &.next-item {
        .page-link {
          background: #F3F2F7;
          .font-icons {
            color: $color-3;
          }
          &:before, &:after {
            display: none;
          }
          &:hover, &:focus {
            background: #F3F2F7 !important;
            color: #6e6b7b;
          }
          &:active {
            color: #fff;
            background: $color-3 !important;
            .font-icons {
              color: inherit;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $sm) {
  .footer.footer {
    padding: 0.8rem calc(2rem - 0.8rem);
    font-size: 12px;
    text-align: center;
  }
}

@media (max-width: $xs) {
  .modal {
    .modal-header {
      min-height: 80px;
      .modal-title {
        .heading-caption {
          font-size: 18px;
          margin: 0;
        }
        .sub-caption {
          font-size: 12px;
        }
      }
      .btn-close {
        display: none;
      }
    }
    .modal-body {
      padding: 28px calc(2rem - 0.8rem);
    }
    .form-buttons {
      margin-top: 30px;
    }
  }
  .auth-page {
    padding: 0;
    .form-block-wrapper {
      background: none;
      width: 100%;
      padding: 14px;
    }
    .form-block {
      background: none;
      box-shadow: none;
      border-radius: 0;
      padding: 0;
    }
    .heading-block {
      text-align: center;
      margin-bottom: 35px;
    }
    .app-logo + * {
      margin-top: 14px;
    }
  }
  .site-balances-page {
    .site-balances-record {
      &.mobile {
        .right-part {
          width: 62%;
        }
      }
    }
  }
  .reports-page {
    .reports-record {
      &.mobile {
        .right-part {
          width: 60%;
        }
      }
    }
  }
}

@media (min-width: $md) {
  .hide-lg {
    display: none !important;
  }
}

@media (min-width: $sm + 1) and (max-width: $md) {
  .hide-md {
    display: none !important;
  }
}

@media (min-width: $xs + 1) and (max-width: $sm) {
  .hide-sm {
    display: none !important;
  }
}

@media (max-width: $xs) {
  .hide-xs {
    display: none !important;
  }
}