@import "style-adaptive";

.reports-page {
  .cards-header {
    margin-bottom: 15px;
  }
  .cards-list {
    display: grid;
    gap: 15px;
  }
  .reports-record {
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.06));
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    margin: 0;
    padding: 0;
    box-shadow: none;
    display: grid;
    grid-template-columns: auto 245px;
    &:hover {
      box-shadow: none;
    }
    .left-part {
      padding: 10px 18px;
      display: grid;
      gap: 15px;
      grid-template-columns: 150px 1fr 0.5fr 0.5fr;
      align-items: center;
    }
    .right-part {
      padding: 10px 18px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      text-align: center;
      justify-content: center;
      align-items: center;
      color: #fff;
      background: linear-gradient(45deg, #7367F0 0%, #9E95F5 100%);
      &.deposit {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(45.79deg, #02B51E 0%, #48DA89 100%, #48DA68 100%);
      }
      &.withdrawal {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(41.83deg, #FF502A 2.18%, #FFB976 100%);
      }
    }
    .item-id {
      font-size: 12px;
      display: flex;
      gap: 5px;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        width: 0;
      }
    }
    .site-name {
      font-weight: 600;
      display: flex;
      color: $color-3;
      & + * {
        margin-top: 5px;
      }
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        width: 0;
      }
    }
    .date {
      font-weight: 500;
      font-size: 18px;
      text-transform: capitalize;
    }
    .time {
      font-size: 12px;
    }
    .transaction-id {
      font-weight: 700;
    }
    .amount-block {
      text-align: right;
      &.deposit {
        color: $color-4;
      }
      &.withdrawal {
        color: $color-2;
      }
      .amount {
        font-size: 14px;
        font-weight: 600;
        color: inherit;
      }
      .commission {
        font-size: 12px;
        color: $color-9;
      }
    }
    .amount-change {
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #fff;
      flex-wrap: wrap;
      span {
        white-space: nowrap;
      }
      i {
        font-size: 14px;
      }
    }
  }

  .day-view-card, .month-view-card {
    .divider-block {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 4px;
      &.positive {
        &:after {
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          background: linear-gradient(45.79deg, #07A624 0%, #48DA89 100%, #40C47A 100%);
        }
      }
      &.negative {
        &:after {
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          background: linear-gradient(41.83deg, #E54C28 2.18%, #E5A266 97.5%);
        }
      }
      &.neutral {
        &:after {
          box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
          background: linear-gradient(45deg, #9D9C9C 0%, #B8B8B8 100%);
        }
      }
      &:before, &:after {
        content: '';
        width: 12px;
        height: 12px;
        flex-shrink: 0;
        border-radius: 1000px;
        box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
        background: #82868B;
      }
      &:after {
        box-shadow: 0 0 0 4px rgba(108, 117, 125, 0.12);
        background: linear-gradient(45.79deg, #07A624 0%, #48DA89 94.75%, #40C47A 94.75%);
      }
      span {
        display: block;
        height: 1px;
        width: 100%;
        background: #82868B;
      }
      &.vertical {
        flex-direction: column;
        width: 20px;
        padding: 4px 18px;
        span {
          height: 100%;
          width: 1px;
        }
      }
    }
  }
  .day-view-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    overflow: hidden;
    .top-part {
      padding: 18px;
      color: #fff;
      background: linear-gradient(45deg, #7367F0 0%, #9E95F5 100%);
      .heading-caption {
        &.date {
          & + * {
            margin-top: 14px;
          }
        }
      }
      .site-name {
        font-size: 16px;
        font-weight: 700;
        & + * {
          margin-top: 20px;
        }
        * {
          color: inherit;
        }
      }
      .updated-at {
        display: flex;
        gap: 10px;
        span {
          font-weight: 600;
        }
      }
      .amount-change {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 28px;
        span {
          display: block;
          font-size: 12px;
          font-weight: 400;
        }
        .font-icons {
          font-size: 32px;
        }
        .amount {
          font-size: 36px;
          font-weight: 700;
          color: #F3F2F7;
        }
      }
    }
    .bottom-part {
      padding: 28px 18px;
      .amount {
        font-size: 21px;
        font-weight: 500;
        & + * {
          margin-top: 28px;
        }
      }
      .caption {
        display: block;
        font-size: 14px;
        font-weight: 400;
      }
      .commission {
        font-size: 21px;
        font-weight: 500;
      }
      .deposit {
        .amount {
          color: $color-4;
        }
      }
      .withdrawal {
        .amount {
          color: $color-2;
        }
      }
    }
  }
  .month-view-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    overflow: hidden;
    .top-part {
      padding: 18px;
      color: #fff;
      background: linear-gradient(45deg, #7367F0 0%, #9E95F5 100%);
      .heading-caption {
        &.date {
          white-space: nowrap;
          & + * {
            margin-top: 7px;
          }
        }
      }
      .site-name {
        font-size: 14px;
        font-weight: 600;
        & + * {
          margin-top: 20px;
        }
        * {
          color: inherit;
        }

      }
      .updated_at {
        display: flex;
        gap: 10px;
        font-size: 12px;
        span {
          font-weight: 600;
        }
      }

    }
    .bottom-part {
      padding: 72px 18px;
      .amount-change {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 28px;
        span {
          display: block;
          font-size: 12px;
          font-weight: 400;
        }
        .amount {
          font-size: 21px;
          font-weight: 500;
        }
      }
      .amount-block {
        display: flex;
        align-items: center;
        gap: 7px;
        .caption {
          font-size: 12px;
        }
        .amount {
          font-weight: 600;
        }
      }
      .deposit {
        .amount {
          color: $color-4;
        }
      }
      .withdrawal {
        .amount {
          color: $color-2;
        }
      }
    }
  }
}

