$bt-width: 100px;
$bt-height: 37px;



@mixin button-constructor (
//$bt-width: 42px,
$bt-height: $bt-height,
$font-size:12px,
$bg-color: linear-gradient(45deg, #7367F0 0%, #9E95F5 100%),
$border-color: $bg-color,
$border-hover-color: $bg-color,
$color:#ffffff,
$bg-hover-color: linear-gradient(45deg, #7367F0 0%, #9E95F5 100%),
$hover-color: #ffffff,
$radius: 5px,
$padding: 8px 18px
) {
  transition: 0.3s ease-out all;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  vertical-align: middle;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  align-items: center;
  -webkit-appearance: none;
  font-size: $font-size;
  font-weight: 500;
  color: $color;
  min-width: $bt-width;
  min-height: $bt-height;
  background: $bg-color;
  border-radius: $radius;
  border: none;
  box-shadow: 0 0 0 2px transparent;
  text-align: center;
  padding: $padding;
  white-space:nowrap;
  box-sizing: border-box;
  line-height: 1.2;
  //text-transform: uppercase;
  &:not(button) {
    display: inline-flex;
    justify-content: center;
  }
  &:not([disabled]) {
    &:visited {
      //outline: none;
      //color: $color;
    }
    &:hover, &:active, &:focus {
      outline: none;
      text-decoration: none;
    }
    &:hover, &:active, &.active {
      color: $hover-color;
    }
    &:hover {
      box-shadow: 0 4px 14px rgba(115, 103, 240, 0.38);
    }
    &:active {
      background: linear-gradient(45deg, #5E50EE 0%, #5E50EE 100%);
      &:not(.link) {
        //box-shadow: inset -3px 0px 3px rgba(0, 0, 0, 0.1), inset 3px 3px 3px rgba(0, 0, 0, 0.1);
      }
    }
    &:focus {
      //background: linear-gradient(45deg, #5E50EE 0%, #5E50EE 100%);
      //background-color: $bg-hover-color;
    }
  }
  * {
    font-size: inherit;
  }
  .img-holder {
    @include size(53px);
    padding: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    overflow: hidden;
  }
  .font-icons, [class *= icon-], .fa {
    font-size: 12px;
    color: inherit;
    &.icon-arrow-down-left,  &.icon-arrow-up-right {
      font-size: 8px;
    }
  }
  &[disabled] {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
  }
  &.size-max {
    flex-grow: 1;
  }
  svg {
    max-width: 12px;
    max-height: 12px;
  }
  &.icon {
    min-width: unset;
    min-height: unset;
    width: $bt-height;
    height: $bt-height;
    padding: 0;
    .font-icons {
      margin: 0;
    }
  }
  &.icon-bg {
    padding-right: 7px;
    justify-content: space-between;
    .font-icons {
      font-size: 14px;
      color: #fff;
      &:not(.no-bg) {
        border-radius: 1000px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-1;
        @include size(26px);
      }
    }
    span {
      vertical-align: middle;
    }
    * + * {
      margin-left: 15px;
    }
  }
  &.size-1 {
    min-width: 200px;
  }
  &.size-2 {
    min-width: 175px;
    min-height: 34px;
  }
  &.size-3 {
    min-width: 150px;
  }
  &.size-max {
    flex-grow: 1;
  }
  &.max-width {
    width: 100%;
  }
  &.rounded {
    border-radius: 4px;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    .font-icons {
      & + span {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #fff;
        padding-left: 12px;
        width: 95px;
        margin-left: 12px;
        text-align: center;
      }
    }
  }
  &.link-view {
    text-transform: capitalize;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .loader {
    width: 16px;
    height: 16px;
    border-width: 2px;
  }
}

.bt {
  @include button-constructor();
  &.transparent {
    background: none;
    color: $color-3;
    box-shadow: 0 0 0 1px $color-3 inset;
    &:not([disabled]) {
      &:hover {
        color: $color-3;
        box-shadow: 0 0 0 1px $color-3 inset;
      }
      &:active {
        background-color: #5E50EE;
        color: #fff;
        box-shadow: 0px 0px 10px transparent;
      }
    }
  }
}

.bt-filled {
  $bt-height: 37px;
  @include button-constructor(
          $bg-color: #7367F0,
          $bg-hover-color: #7367F0,
          $bt-height: 37px
  );
  &:not([disabled]) {
    &:hover {
      box-shadow: 0px 0px 10px rgba(115, 103, 240, 0.65);
    }
    &:active {
      background-color: #5E50EE;
      box-shadow: 0px 0px 10px transparent;
    }
  }
}

.bt-grey {
  @include button-constructor (
          $bg-color: #fff,
          $color: #82868B,
          $bg-hover-color: #fff,
          $border-hover-color: #82868B,
          $hover-color: #82868B
  );
  box-shadow: 0 0 0 1px #82868B inset;
  &:not([disabled]) {
    &:hover {
      background: rgba(130, 134, 139, 0.05);
      box-shadow: 0 0 0 1px #82868B inset;
    }
    &:active {
      background: rgba(130, 134, 139, 0.15);
      box-shadow: 0 0 0 1px #82868B inset;
    }
  }
}

.bt-white {
  @include button-constructor (
          $bg-color: #ffffff,
          $color: $color-3,
          $bg-hover-color: #fff,
          $hover-color: $color-3
  );
  box-shadow: 0 0 0 1px $color-3 inset;
  .loader {
    border-color: $color-3;
    border-bottom-color: transparent;
  }
  &:not([disabled]) {
    &:hover {
      background: linear-gradient(0deg, rgba(115, 103, 240, 0.12), rgba(115, 103, 240, 0.12)), #FFFFFF;
      box-shadow: 0 0 0 1px $color-3 inset;
    }
    &:active {
      background: linear-gradient(0deg, rgba(115, 103, 240, 0.2), rgba(115, 103, 240, 0.2)), #FFFFFF;
      box-shadow: 0 0 0 1px $color-3 inset;
    }
  }
}

.bt-border {
  @include button-constructor (
          $bg-color: transparent,
          $color: $color-3,
          $bg-hover-color: rgba(115, 103, 240, 0.04),
          $hover-color: $color-3
  );
  box-shadow: none;
  border: 1px solid;
  &:not([disabled]) {
    &:hover {
      background: rgba(115, 103, 240, 0.1);
      box-shadow: none;
    }
    &:active {
      background: rgba(115, 103, 240, 0.2);
      box-shadow: none;
    }
  }
}

.bt-red {
  @include button-constructor (
          $bg-color: #EA5455,
          $color: #fff,
          $bg-hover-color: #EA5455,
          $hover-color: #fff
  );
  box-shadow: 0 0 0 1px #EA5455 inset;
  &:not([disabled]) {
    &:hover {
      background: #EA5455;
      box-shadow: 0px 4px 14px rgba(234, 84, 85, 0.38);

    }
    &:active {
      background: #E73D3E;
      box-shadow: 0 0 0 1px #E73D3E inset;
    }
  }
}

.bt-green {
  @include button-constructor (
          $bg-color: linear-gradient(45.79deg, #07A624 0%, #48DA89 100%, #40C47A 100%),
          $color: #fff,
          $bg-hover-color:linear-gradient(45.79deg, #07A624 0%, #48DA89 100%, #40C47A 100%),
          $hover-color: #fff
  );
  &:not([disabled]) {
    &:hover {
      box-shadow: 0px 4px 14px rgba(40, 199, 111, 0.38);
    }
    &:active {
      background: linear-gradient(86.57deg, #049B1E 0%, #3DB974 100%);
      box-shadow: 0px 0px 10px transparent;
    }
  }
}

.bt-orange {
  @include button-constructor (
          $bg-color: linear-gradient(41.83deg, #E54C28 2.18%, #E5A266 100%),
          $color: #fff,
          $bg-hover-color:linear-gradient(41.83deg, #E54C28 2.18%, #E5A266 100%),
          $hover-color: #fff
  );
  &:not([disabled]) {
    &:hover {
      box-shadow: 0px 4px 14px rgba(255, 159, 67, 0.38);
    }
    &:active {
      background: linear-gradient(264.37deg, #DA985F 0%, #DA4B28 100%);
      box-shadow: 0px 0px 10px transparent;
    }
  }
}

.bt-icon {
  width: 34px;
  height: 34px;
  padding: 0;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 1000px !important;
  transition: 0.3s ease-out all;
  cursor: pointer;
  .font-icons {
    font-size: 12px;
    margin: 0;
  }
  &:hover {
    color: #7367F0;
  }
  &.size-2 {
    width: 16px;
    height: 16px;
    .font-icons {
      font-size: 16px;
    }
  }
  &.size-3 {
    width: 24px;
    height: 24px;
    .font-icons {
      font-size: 24px;
    }
  }
  &.transparent {
    width: 38px;
    height: 38px;
    background: none;
    border-radius: 0px !important;
  }
  &.red {
    color: #FF4445;
    background: none;
    box-shadow: 0 0 0 1px #FF4445 inset;
    &:hover {
      background: #FF4445;
      color: #fff;
    }
  }
}



.link {
  cursor: pointer;
  color: inherit;
  &:hover {
    color: inherit;
  }
  &.no-underline {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  * {
    vertical-align: middle;
    & + * {
      margin-left: 5px;
    }
  }
  &.color-primary {
    color: $color-3;
  }
}

button, .button {
  &.link {
    min-width: $bt-width;
    min-height: $bt-height;
    font-weight: 500;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }
}
















.bt-grey-lite {
  @include button-constructor (
$bg-color: #eeeeee,
$color: #222222,
$bg-hover-color: #4d2253,
$hover-color: #fff,
$border-hover-color: transparent
);
  box-shadow: none;
}

.buttons-holder {
  display: flex;
  flex-wrap: wrap;
  gap: $padding-1;
  &.size-max {
    flex-grow: 1;
  }
  &.direction-vertical {
    flex-direction: column;
    &>* {
      width: auto;
    }
  }
  &.inline {
    display: inline-flex;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-right {
    justify-content: right;
  }
  &.align-center {
    align-items: center;
  }
  &.align-left {
    align-items: flex-start;
  }
}