.site-balances-page {
  .site-balances-record {
    &.mobile {
      padding: 12px 10px;
      display: block;
      .status-block {
        position: relative;
        width: 34px;
        height: 34px;
        .action-icon {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          color: #fff;
          width: 34px;
          height: 34px;
          border-radius: 1000px;
          overflow: hidden;
          font-size: 16px;
          &.deposit {
            background: linear-gradient(45.79deg, #07A624 0%, #48DA89 94.75%, #40C47A 94.75%);
          }
          &.withdrawal {
            background: linear-gradient(41.83deg, #E54C28 2.18%, #E5A266 97.5%);
          }
        }
      }
      .item-description {
        line-height: 100%;
        & + * {
          margin-top: 8px;
        }
      }
      .transaction-id {
        display: block;
        width: max-content;
        line-height: 100%;
        font-size: 12px;
        font-weight: 400;
        color: $color-3;
      }

      .date-time {
        display: block;
        width: 100%;
        font-weight: 400;
        color: $color-9;
        font-size: 12px;
        line-height: 100%;
      }
      .amount {
        text-align: right;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        & + * {
          margin-top: 8px;
        }
      }
      .destination-payment {
        text-align: right;
        color: $color-9;
        font-size: 12px;
      }
    }
  }
}

.site-balance-view-card {
  &.mobile {
    .top-part {
      padding: 15px;
      .row-id-date {
        & + * {
          margin-top: 14px;
        }
      }
      .heading-caption {
        & + .sub-caption {
          margin-top: 7px;
        }
      }
      .sub-caption {
        font-size: 12px;
      }
      .item-id {
        font-size: 12px;
      }
      .date {
        font-weight: 600;
      }
      .col-date-time {
        display: flex;
        gap: 7px;
        font-size: 12px;
      }
    }
    .bottom-part {
      padding: 24px 15px;
      display: flex;
      flex-direction: column;
      gap: 14px;
      .caption {
        margin-bottom: 0;
        text-align: left;
        font-size: 12px;
        font-weight: 400;
      }
      .amount {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}