.account-page {
  .items-list {
    &.mobile {
      .content-block {
        &.filled {
          padding: 18px;
        }
      }
      .single-setting {
        display: grid;
        row-gap: 10px;
      }
    }
  }
}

@media (max-width: $sm - 1) {
  .modal-dialog {
    &.account-settings {
      &.ip-whitelist {
        .status-image {
          font-size: 100px;
        }
        .list-items {
          gap: 28px;
          .list-item {
            &>.columns-row {
              row-gap: 7px;
            }
          }
        }
      }
    }
  }
}