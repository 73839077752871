@import "style-adaptive";

.account-page {
  .content-block {
    &.filled {
      display: grid;
      grid-gap: 28px;
    }
    .account-text {
      .heading-caption {
        margin-bottom: 2px;
      }
    }
    .account-data {
      font-weight: 500;
      font-size: 18px;
      color: $color-3;
    }
  }
}

.modal-dialog {
  &.account-settings {
    .image-qr-code {
      width: 120px;
      height: 120px;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid $color-6;
      img {
        max-width: 100%;
      }
    }
    .status-image {
      display: flex;
      justify-content: center;
      font-size: 100px;
      .font-icons {
        font-size: inherit;
        &.success {
          color: $color-4;
        }
        &.alert {
          color: $color-5;
        }
      }
      & + * {
        margin-top: 25px;
      }
    }
  }
  &.ip-whitelist {
    .status-image {
      .font-icons {
        margin: 0;
      }
    }
    .ip-list-block {
      margin-top: 28px;
      border-top: 1px solid #EBE9F1;
      padding-top: 14px;
      padding-bottom: 14px;
      .line-divider {
        margin: 14px 0;
      }
      .current-ip {
        display: inline-flex;
        gap: 5px;
        align-items: baseline;
        cursor: pointer;
      }
      .bt-icon{
        &.button-delete-ip {
          display: flex;
          font-size: 21px;
          color: $color-6;
          width: auto;
          .font-icons {
            font-size: inherit;
          }
        }
      }
      .list-items {
        display: grid;
        gap: 14px;
        margin-top: 14px;
      }
    }
  }
}