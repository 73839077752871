.notifications-page {
  .notification-record {
    &.mobile {
      padding: 12px 10px;
      .status-block {
        position: relative;
        width: 34px;
        height: 34px;
        .status-icon {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          color: #fff;
          width: 34px;
          height: 34px;
          border-radius: 1000px;
          overflow: hidden;
          font-size: 14px;
          &.error {
            background: $color-2;
          }
          &.success {
            background: $color-4;
          }
          &.process {
            background: $color-5;
          }
          &.cancel {
            background: $color-6;
          }
        }

        .status-count {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -1px;
          bottom: -1px;
          width: 12px;
          height: 12px;
          border-radius: 1000px;
          color: #fff;
          font-size: 8px;
          box-shadow: 0 0 0 1px #fff;
          z-index: 1;
          &.fail {
            background: $color-2;
          }
          &.success {
            background: $color-4;
          }
          &.intermediate {
            background: $color-5;
          }
        }
      }
      .item-id {
        display: block;
        line-height: 100%;
        font-size: 12px;
        font-weight: 600;
        & + * {
          margin-top: 8px;
        }
      }
      .transaction-id {
        display: block;
        line-height: 100%;
        font-size: 10px;
        font-weight: 400;
      }



      .date-time {
        display: block;
        width: 100%;
        color: $color;
        text-align: right;
        .date {
          font-weight: 500;
          font-size: 12px;
          line-height: 100%;
          & + * {
            margin-top: 8px;
          }
        }
        .time {
          font-weight: 400;
          font-size: 10px;
          line-height: 100%;
        }
      }
    }
  }
}

.notification-view {
  &.mobile {
    .top-part {
      padding: 20px 14px 28px 14px;
    }
    .heading-caption {
      & + .sub-caption {
        margin-top: 7px;
      }
    }
    .sub-caption {
      font-size: 12px;
      font-weight: 600;
      display: flex;
      gap: 14px;
    }
    .status-list {
      margin-top: 14px;
      margin-bottom: 28px;
    }
    .date-time {
      font-size: 12px;
      & + * {
        margin-top: 28px;
      }
    }
    .notification-url {
      font-weight: 600;
      font-size: 14px;
      & + * {
        margin-top: 28px;
      }
    }
  }
}