@import "style-adaptive";

.notifications-page {
  .header-panel {
    margin-bottom: 15px;
  }
  .cards-list {
    display: grid;
    gap: 15px;
  }
  .notification-record {
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.06));
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    margin: 0;
    padding: 0;
    box-shadow: none;
    display: grid;
    grid-template-columns: 1fr;
    &:hover {
      box-shadow: none;
    }
    .left-part {
      padding: 0 18px;
      display: grid;
      gap: 15px;
      grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr) 5fr minmax(auto, 1fr) 50px 30px auto;
      min-height: 50px;
      align-items: center;
    }
    .right-part {
      padding: 0 18px;
      display: flex;
      gap: 7px;
      text-align: right;
      justify-content: flex-end;
      align-items: center;
      color: #fff;
      background: linear-gradient(45deg, #7367F0 0%, #9E95F5 100%);
      .transaction-type {
        font-size: 16px;
      }
    }
    .col-id {

    }
    .col-date {
      text-align: center;
    }
    .col-status {
      text-align: center;
    }
    .col-request {
      text-align: center;
    }
    .col-response {
      text-align: center;
    }
    .item-id {
      display: flex;
      gap: 5px;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        width: 0;
      }
    }
    .site-name {
      font-weight: 700;
      display: flex;
      color: $color-3;
      .holder {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
        width: 0;
      }
    }
  }
}

.notification-view {
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  overflow: hidden;
  .top-part {
    padding: 18px;
    color: #fff;
    background: linear-gradient(45deg, #7367F0 0%, #9E95F5 100%);
    .heading-caption {
      &.s-2 {
        font-size: 24px;
        margin-bottom: 5px;
      }
      & + .sub-caption {
        font-size: 12px;
        margin-bottom: 14px;
      }
    }
    .date {
      text-align: right;
      width: 100%;
      & + .buttons-holder {
        margin-top: 14px;
      }
      & + * {
        margin-top: 5px;
      }
      span {
        font-weight: 600;
      }
    }
    .bt-white {
      .font-icons {
        font-size: 14px;
      }
    }
  }
  .bottom-part {
    padding: 28px 18px;
    .heading-caption {
      margin-bottom: 14px;
    }
    .first-column {
      border-right: 1px solid #EBE9F1;
    }
  }

  .col-status-text {
    margin-left: 15px;
  }
}