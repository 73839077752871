.reports-page {
  .reports-record {
    &.mobile {
      padding: 12px 10px;
      display: block;
      .image-block {
        position: relative;
        width: 34px;
        height: 34px;
        .action-icon {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          color: #fff;
          width: 34px;
          height: 34px;
          border-radius: 1000px;
          overflow: hidden;
          font-size: 18px;
          &.positive {
            background: linear-gradient(45.79deg, #07A624 0%, #48DA89 94.75%, #40C47A 94.75%);
          }
          &.negative {
            background: linear-gradient(41.83deg, #E54C28 2.18%, #E5A266 97.5%);
          }
          &.neutral {
            background: linear-gradient(45deg, #9D9C9C 0%, #B8B8B8 100%);
          }
        }
      }
      .date-time {
        display: block;
        width: 100%;
        color: $color;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        text-transform: capitalize;
        & + * {
          margin-top: 2px;
        }
      }
      .site-name {
        font-size: 12px;
        font-weight: 400;
      }
      .amount {
        text-align: right;
        font-weight: 500;
        font-size: 15px;
        color: $color;
        &.positive {
          color: $color-4;
        }
      }
    }
  }

  .day-view-card, .month-view-card {
    &.mobile {
      .top-part {
        padding: 15px;
        &.positive {
          background: linear-gradient(45.79deg, #07A624 0%, #48DA89 100%, #40C47A 100%);
        }
        &.negative {
          background: linear-gradient(41.83deg, #E54C28 2.18%, #E5A266 97.5%);
        }
        .heading-caption {
          &:first-letter {
            text-transform: capitalize;
          }
          & + * {
            margin-top: 4px;
          }
          &.date {
            & + * {
              margin-top: 8px;
            }
          }
        }
        .site-name {
          font-weight: 400;
          font-size: 14px;
          &:first-letter {
            text-transform: capitalize;
          }
          & + * {
            margin-top: 14px;
          }
        }
        .updated-at {
          font-size: 12px;
          span {
            font-weight: 600;
          }
          & + * {
            margin-top: 21px;
          }
        }
      }
      .bottom-part {
        padding: 28px 14px;
        text-align: center;
        .columns-amount-blocks {
          & + * {
            margin-top: 14px;
          }
        }
        .amount-change {
          display: block;
          & + * {
            margin-top: 14px;
          }
          .item {
            text-align: center;
            font-size: 12px;
            .amount {
              font-size: 21px;
              font-weight: 500;
            }
          }
        }
        .amount-block {
          display: block;
          text-align: center;
          color: $color-9;
          margin: 34px 0;
          .caption {
            font-size: 12px;
          }
          .amount {
            font-size: 14px;
            font-weight: 600;
          }
          .count {
            color: $color-6;
            font-size: 12px;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 14px;
          }
          .commission {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
